import React from "react";
import { Link } from "gatsby";

import Layout from "../../components/layout";

// Assets

const CareersFirmwareEngineer = () => (
  <Layout currentPage="careers-listing">
    <div className="container">
      <Link to="/careers#alljobs">
        <p className="backbutton col-md-12">Back to Careers</p>
      </Link>
      <h1 className="jobtitle col-md-12">B2B Sales Lead</h1>
      <div className="jobdescription col-md-12">
        Make an impact by showing the world that what’s good for the planet is
        good for business.
      </div>
      <div className="row col-md-12 justify-content-md-between">
        <div className="col-md-3">
          <div className="aligned-left bold-text jobsectiontitle">
            You will:
          </div>
        </div>
        <div className="col-md-9">
          <ul className="aligned-left jobsection">
            <li>
              Sell the Uno vision and products to workplaces by generating leads
              and closing deals.{" "}
            </li>
            <li>
              Work with our leadership, marketing team and business development
              team on identifying new needs, opportunities and sales channels.
            </li>
            <li>
              Work with marketing team to develop B2B-specific communication
              work.{" "}
            </li>
          </ul>
        </div>

        <div className="col-md-3">
          <div className="aligned-left bold-text jobsectiontitle">You are:</div>
        </div>
        <div className="col-md-9 ">
          <ul className="aligned-left jobsection">
            <li>First and foremost, great at closing deals.</li>
            <li>Naturally entrepreneurial and convincing presenter.</li>
            <li>
              Hardworking at establishing, building and growing relationships.{" "}
            </li>
            <li>Confident in meetings and negotiations.</li>
            <li>
              Able to distinguish between and pursue both transactional versus
              strategic wins
            </li>
            <li>
              Good at reading a room and adjusting your sales pitch on the fly.
            </li>
          </ul>
        </div>

        <div className="col-md-3">
          <div className="aligned-left bold-text jobsectiontitle">
            You bring:
          </div>
        </div>
        <div className="col-md-9 ">
          <ul className="aligned-left jobsection">
            <li>3+ years of sales experience (preferably at a startup)</li>
            <li>Understanding of B2B sales processes and common practices.</li>
            <li>
              Experience dealing with large organizations and navigating
              political environments.
            </li>
            <li>
              A network of people you can tap into for leads and connections.
            </li>
            <li>Original ideas for how to access key decision makers.</li>
            <li>
              Ability to discover the true needs of a client and adapt quickly.{" "}
            </li>
            <li>Endless resilience to failure.</li>
            <li>Passion for great products and sustainability.</li>
            <li>Experience with Salesforce.</li>
          </ul>
        </div>

        <div className="col-md-3">
          <div className="aligned-left bold-text jobsectiontitle">
            You apply:
          </div>
        </div>
        <div className="col-md-9">
          <div className="aligned-left" style={{ paddingBottom: "100px" }}>
            By sending us an <a href="mailto:careers@introducing.uno">email</a>{" "}
            with your resume and a cover letter.
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default CareersFirmwareEngineer;
